import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadHome } from 'src/components/Skeleton/LoadHome/LoadHome'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathQueryParams } from 'src/routes/pathQueryParams'
import { EligibilityActions } from 'src/store/ducks/eligibility/actions'
import { ContainerFullPage } from 'src/styles/commons'

export const Start: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handler = PathQueryParams()

  useEffect(() => {
    handler()
    dispatch(
      EligibilityActions.getEligibilityRequest({
        history,
        pathname: TypesRoutes.LANDING_PAGE,
      }),
    )
  }, [])

  return (
    <ContainerFullPage height="0px" margin="0" overflowHidden>
      <LoadHome />
    </ContainerFullPage>
  )
}
