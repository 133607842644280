import { combineReducers } from 'redux'

import eligibility from './eligibility'
import error from './error'
import loading from './loading'
import navbar from './navbar'
import pathparams from './pathparams'
import proposal from './proposal'

const rootReducer = combineReducers({
  navbar,
  loading,
  error,
  eligibility,
  proposal,
  pathparams,
})

export default rootReducer
