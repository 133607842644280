import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { ProposalActions } from 'src/store/ducks/proposal/actions'
import { AlertSign, H1, H2, PMedium, PSmall, PXSmall, SuccessSign } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { maskCnpj } from 'src/utils/masks'
import { useTheme } from 'styled-components'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'

import {
  InterUIAlert,
  InterUIContainer,
  InterUIList,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { ContentFooter, InlineList } from './RequestSent.styles'

export const RequestSent: React.FC = () => {
  const theme = useTheme()
  const iconSize = 32

  const checkout = useSelector(ProposalActions.getCheckoutProposal)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const successIcon = (
    <Icons.Check width={iconSize} height={iconSize} color={theme.colors.base.white} />
  )
  const attentionIcon = (
    <Icons.Attention width={iconSize} height={iconSize} color={theme.colors.base.white} />
  )

  useBasePage({
    navbarTitle: PageTitles.MOST_CARD_PROTECTED,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics('CARTAOPROT_SUCESSO', {
      ref_figma: '6',
      ref_type: 'tela',
      screen: 'Solicitação Enviada',
      content_action: 'Dado Carregado',
      plans: checkout.planType,
      value: numberFormat(checkout.planValue),
      payment_method: checkout.paymentMethod === 'CREDIT' ? 'Crédito' : 'Débito',
      status: checkout.status === 'ATIVO' ? 'Sucesso' : 'Analise',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
    BaseBridge.tacWb({
      name: 'receipt_screen',
      module: 'protected_card_pj',
      actions: ['subscribe_insurance'],
      params: {
        value: numberFormat(checkout.planValue),
        plans: checkout.planType,
        payment_method: checkout.paymentMethod === 'CREDIT' ? 'Crédito' : 'Débito',
        status: checkout.status === 'ATIVO' ? 'Sucesso' : 'Analise',
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
      },
    })
  }, [])

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button variant="primary" margin="0 0 16px" onClick={() => BaseBridge.requestGoBack()}>
        Ir para Inter Empresas
      </Button>
    </InterUIContainer>
  )

  const renderHeroSuccess = (
    <>
      <SuccessSign>{successIcon}</SuccessSign>
      <H1 margin="24px 0 8px">Seguro contratado com sucesso</H1>
      <PMedium scale={400} marginBottom="0">
        Em breve, você receberá todos os detalhes do seu seguro no seu e-mail.
      </PMedium>
    </>
  )

  const renderHeroAttention = (
    <>
      <AlertSign>{attentionIcon}</AlertSign>
      <H1 margin="24px 0 8px">Sua solicitação está sendo analisada</H1>
      <PMedium scale={400} marginBottom="0">
        Em breve, você receberá o retorno dessa solicitação por e-mail. Caso ela seja aprovada, os
        detalhes do seguro também chegarão lá.
      </PMedium>
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="24px 24px 32px">
        {checkout.status === 'ATIVO' ? renderHeroSuccess : renderHeroAttention}
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px">
        <H2 marginBottom="16px">Dados do seguro</H2>
        <InterUIList
          variant="vertical"
          margin="0 0 16px"
          value={
            <PMedium bold marginBottom="0px">
              {maskCnpj(checkout.cnpj)}
            </PMedium>
          }
          text={
            <PMedium scale={400} marginBottom="4px">
              CNPJ da empresa:
            </PMedium>
          }
        />
        <InlineList>
          <InterUIList
            variant="vertical"
            value={
              <PMedium bold marginBottom="0px">
                {checkout.planType}
              </PMedium>
            }
            text={
              <PMedium scale={400} marginBottom="4px">
                Plano
              </PMedium>
            }
          />

          <InterUIList
            variant="vertical"
            value={
              <PMedium bold marginBottom="0px">
                {numberFormat(checkout.planValue)}
              </PMedium>
            }
            text={
              <PMedium scale={400} marginBottom="4px">
                Valor da mensalidade
              </PMedium>
            }
          />
        </InlineList>
        <InterUIList
          variant="vertical"
          margin="0 0 16px"
          value={
            <PMedium bold marginBottom="0px">
              {checkout.paymentMethod === 'CREDIT' ? 'Cartão de Crédito' : 'Débito em conta'}
            </PMedium>
          }
          text={
            <PMedium scale={400} marginBottom="4px">
              Forma de pagamento
            </PMedium>
          }
        />
        <InterUIAlert type="attention">
          <PSmall marginBottom="4px" bold>
            Acionamento do seguro
          </PSmall>
          <PSmall marginBottom="0">
            Lembre-se que o seguro deve ser acionado pelo representante legal da empresa
          </PSmall>
        </InterUIAlert>
      </InterUIContainer>

      <InterUIContainer margin="0">
        <ContentFooter>
          <PXSmall marginBottom="0">
            Liberty Seguros S/A - CNPJ 61.550.141/0001-72 - Código SUSEP 518-5 Processo SUSEP
            15414.901525/2017-26. O registro do produto é automático e não representa aprovação ou
            recomendação por parte da Susep. SAC 0800 726 1981 – Reclamações, cancelamentos e
            informações gerais. Caso não fique satisfeito com a solução do nosso time de
            atendimento, poderá recorrer a Ouvidoria*Título de pagamento único da modalidade
            incentivo emitido pela ICATU CAPITALIZAÇÃO S/A, CNPJ/MF nº 74.267.170/0001-73, Processo
            SUSEP Nº 15414.900631/2019-54. Ouvidoria Icatu Seguros 0800 286 0047. Para mais
            informações consulte as condições gerais, o regulamento e as características.
          </PXSmall>
        </ContentFooter>
      </InterUIContainer>
    </InterUIContainer>
  )
}
